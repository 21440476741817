var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_c("app-header")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        [
          _vm.loading
            ? _c("v-progress-circular", {
                staticClass: "mt-10",
                attrs: { color: "inqliPurple", indeterminate: "" }
              })
            : _c("div", [
                _c(
                  "div",
                  { staticClass: "main-content" },
                  [
                    _c(
                      "v-card",
                      {
                        key: _vm.userId,
                        staticClass: "user-profile",
                        attrs: { rounded: "lg", flat: "" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "share-button" },
                          [
                            _c("share-link-dialog", {
                              attrs: {
                                shareUrl: _vm.shareUrl,
                                toolTipPlace: "left"
                              }
                            }),
                            _vm._l(_vm.menuItems, function(menuItem) {
                              return _c(
                                "div",
                                { key: menuItem.label },
                                [
                                  !menuItem.hide
                                    ? _c(
                                        "v-tooltip",
                                        {
                                          attrs: { left: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass: "pt-3",
                                                            attrs: {
                                                              color: menuItem.color
                                                                ? menuItem.color
                                                                : "inqliPurple",
                                                              "aria-label":
                                                                menuItem.ariaLabel
                                                            },
                                                            on: {
                                                              click:
                                                                menuItem.action
                                                            }
                                                          },
                                                          "v-icon",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(menuItem.icon)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(menuItem.label))
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            })
                          ],
                          2
                        ),
                        _c("user-general-detail", {
                          attrs: { user: _vm.user }
                        }),
                        _vm.topQuestions.length > 0
                          ? _c(
                              "div",
                              { staticClass: "questions" },
                              [
                                _c("h2", [_vm._v("Top Questions")]),
                                _vm._l(_vm.topQuestions, function(question) {
                                  return _c("top-question-card", {
                                    key: question.question.id,
                                    attrs: {
                                      question: question.question,
                                      "reply-count":
                                        question.metadata.reply_count
                                    }
                                  })
                                })
                              ],
                              2
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }