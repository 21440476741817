<template>
  <layout>
    <template #header>
      <app-header></app-header>
    </template>
    <div>
      <v-progress-circular
        v-if="loading"
        color="inqliPurple"
        indeterminate
        class="mt-10"
      ></v-progress-circular>
      <div v-else>
        <div class="main-content">
          <v-card class="user-profile" rounded="lg" flat :key="userId">
            <div class="share-button">
              <share-link-dialog :shareUrl="shareUrl" toolTipPlace="left" />
              <div v-for="menuItem in menuItems" :key="menuItem.label">
                <v-tooltip left v-if="!menuItem.hide">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="menuItem.color ? menuItem.color : 'inqliPurple'"
                      @click="menuItem.action"
                      class="pt-3"
                      v-bind="attrs"
                      v-on="on"
                      :aria-label="menuItem.ariaLabel"
                      >{{ menuItem.icon }}</v-icon
                    >
                  </template>
                  <span>{{ menuItem.label }}</span>
                </v-tooltip>
              </div>
            </div>
            <user-general-detail :user="user" />
            <div class="questions" v-if="topQuestions.length > 0">
              <h2>Top Questions</h2>
              <top-question-card
                v-for="question in topQuestions"
                :key="question.question.id"
                :question="question.question"
                :reply-count="question.metadata.reply_count"
              />
            </div>
          </v-card>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>
import sendRequest from "../app/utils/send-request-helper";
import TopQuestionCard from "../app/components/questions/QuestionSectionCard.vue";
import ShareLinkDialog from "../app/components/dialogs/ShareLinkDialog.vue";
import handleRequestMixin from "../app/mixins/handle-request-mixin";
import UserGeneralDetail from "./components/UserGeneralDetail.vue";
import Header from "../app/components/headers/DefaultHeader";
import DefaultLayout from "../app/components/layouts/DefaultLayout";

export default {
  name: "UserDetail",
  metaInfo: {
    title: "User profile",
  },
  components: {
    "top-question-card": TopQuestionCard,
    "share-link-dialog": ShareLinkDialog,
    "user-general-detail": UserGeneralDetail,
    appHeader: Header,
    layout: DefaultLayout,
  },
  mixins: [handleRequestMixin],
  data() {
    return {
      loading: true,
      user: null,
      userId: this.$route.params.id,
      topQuestions: [],
    };
  },
  watch: {
    user(val) {
      if (val && val.display_name && val != undefined) {
        this.loading = false;
      }
    },
  },
  computed: {
    shareUrl() {
      return `${window.location.origin}/users/${this.$route.params.id}`;
    },
    menuItems() {
      return [
        {
          label: "Edit profile",
          ariaLabel: "edit-user",
          icon: "mdi-pencil",
          action: this.editUser,
          hide: !this.isCurrentUserProfile,
        },
      ];
    },
    isCurrentUserProfile() {
      return this.userId == this.$store.state.user.username;
    },
  },
  async beforeRouteUpdate(to, from, next) {
    next();
    this.userId = to.params.id;
    this.getUserTopQuestions();
    await this.loadUserData();
  },
  async mounted() {
    this.getUserTopQuestions();
    this.loadUserData();
  },
  methods: {
    editUser() {
      this.$router.push("/users/edit");
    },

    async getUserTopQuestions() {
      const getTopQuestions = async function () {
        const response = await sendRequest({
          url: `/resources/questions?user_id=${this.userId}&relationship=asked&limit=3`,
          method: "GET",
          isAuth: false,
          requestLabel: "getting user questions",
          itemName: "user questions",
        });
        this.topQuestions = response.list;
      }.bind(this);
      await this.handleRequestMixin_alertError(getTopQuestions);
    },
    async loadUserData() {
      const getUserData = async function () {
        this.user = await sendRequest({
          url: `/resources/users/${this.$route.params.id}`,
          method: "GET",
          isAuth: false,
          requestLabel: "getting user data",
          itemName: "user",
        });
      }.bind(this);
      await this.handleRequestMixin_alertError(getUserData);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  margin-top: 200px;
}
.verified-badge {
  display: inline-block;
  background-color: $inqliGreen;
  width: 100px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: bold;
  margin: 0 auto;
  margin-top: 15px;
}
.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-image {
  margin: 10px;
  width: 200px;
  border-radius: 50%;
}
.verified-badge {
  padding: 6px 14px;
  font-size: 12px;
  background: #c9eee1;
  color: rgb(23, 23, 90);
  margin: 6px;
}
.about {
  margin: auto;
  margin-top: 30px;
  &-body {
    text-align: center;
  }
}
.tag-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.questions {
  width: 95%;
  margin: auto;
  margin-top: 15px;
}
.avatar {
  margin: auto;
}
.user-profile {
  margin-top: 15px;
  width: 55%;
  padding: 15px 64px;
}
.share-button {
  position: absolute;
  right: 10px;
  top: 22px;
}
@media (max-width: $breakpoint-tablet-large) {
  .user-profile {
    width: 85%;
  }
  .questions {
    width: 100%;
  }
}
@media (max-width: $breakpoint-mobile) {
  .user-profile {
    width: 90%;
    padding: 15px;
  }
  .questions {
    width: 100%;
  }
  .share-button {
    position: absolute;
    right: 0;
    top: 25px;
  }
}
</style>
