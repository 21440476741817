import { render, staticRenderFns } from "./UserDetailPage.vue?vue&type=template&id=b9f76bea&scoped=true&"
import script from "./UserDetailPage.vue?vue&type=script&lang=js&"
export * from "./UserDetailPage.vue?vue&type=script&lang=js&"
import style0 from "./UserDetailPage.vue?vue&type=style&index=0&id=b9f76bea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9f76bea",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VIcon,VProgressCircular,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/matt/Documents/inqli/product/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b9f76bea')) {
      api.createRecord('b9f76bea', component.options)
    } else {
      api.reload('b9f76bea', component.options)
    }
    module.hot.accept("./UserDetailPage.vue?vue&type=template&id=b9f76bea&scoped=true&", function () {
      api.rerender('b9f76bea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/users/UserDetailPage.vue"
export default component.exports